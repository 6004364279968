import React, { lazy, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import SnowTheme from "././SnowTheme";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";

const Layout = lazy(() => import("./containers/Layout"));
const login_introduction = lazy(() => import("./pages/Login_Introduction"));

function App() {
  var dotenv = require("dotenv");
  var dotenvExpand = require("dotenv-expand");
  var myEnv = dotenv.config();
  dotenvExpand.expand(myEnv);
  const [is_christmas_time, set_is_christmas_time] = useState(false);
  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    _is_christmas_time();
  }, []);

  const _is_christmas_time = async () => {
    const now = new Date().toLocaleDateString();
    const Dec = 12;

    let time = now.split("T")[0];
    const curr_date = time.split("-")[0].split("/")[1];

    if (parseInt(curr_date) === Dec) {
      set_is_christmas_time(true);
    } else {
      set_is_christmas_time(false);
    }
  };

  return (
    <>
      <Router>
        {is_christmas_time ? <SnowTheme /> : <></>}
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/introduction" component={login_introduction} />
          <Route path="/app" component={Layout} />
          <Redirect exact from="/" to="/introduction" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
